import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link, useStaticQuery, graphql } from "gatsby"

const LogoWrap = styled.div`
    height: 3rem;

    @media (min-width: 768px) {
      height: 4rem;
    }
  }
`

const StyledImage = styled.img`
max-height: 100%;
`

const Logo = props => {
  const data = useStaticQuery(graphql`
    query {
      file(name: {eq: "logo-horizontal"}, extension: {eq: "svg"}) {
        publicURL
      }
    }
  `)

  return (
    <LogoWrap as={Link} to="/">
      <StyledImage src={data.file.publicURL} alt="logo" />
    </LogoWrap>
  )
}

export default Logo
