import React, { useState } from "react"
import styled from "styled-components"

import Logo from "./Logo"
import Burger from "../Burger/Burger"
import Menu from "../Menu/Menu"

const TopNavWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 4vw;
  a {
    background-image: none !important;
  } 
`

const TopNav = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  console.log(`isMenuOpen: ${isMenuOpen}, setIsMenuOpen: ${setIsMenuOpen.toString()}`)
  return (
    <>
      <TopNavWrap>
        <Logo />
        <Burger isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </TopNavWrap>
      <Menu isMenuOpen={isMenuOpen} />
    </>
  )
}

export default TopNav
