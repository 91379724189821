import React from "react"
import { bool } from "prop-types"
import styled from "styled-components"

export const StyledMenu = styled.nav`
  display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }

  a {
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    transition: color 0.3s linear;
    text-decoration: none;
    background-image: none !important;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }
`

const Menu = ({ isMenuOpen }) => {
  return (
    <StyledMenu isMenuOpen={isMenuOpen}>
      <a href="/about">About</a>
      <a href="/services">Services</a>
      <a href="/clients">Clients</a>
      <a href="#contact_us">Contact</a>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
