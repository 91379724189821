import React from "react"
import styled from "styled-components"
import { bool, func } from "prop-types"

export const StyledBurger = styled.button`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background: black;
  }
`

const Burger = ({isMenuOpen, setIsMenuOpen}) => {
  return (
    <StyledBurger isMenu={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}

Burger.defaultProps = {
  open: false,
}
export default Burger
