import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"
import Logo from "../../components/TopNav/Logo"

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
`

const Copyright = styled.div`
  background-color: ${({ theme }) => theme.colors.grayLighter};
  padding: 1rem;
  font-size: 0.8rem;
`

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media(min-width: 768px) {
    flex-direction: row;
    align-items: center;
}
`

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  @media (min-width: 768px) {
    justify-content: start;
    margin-left: 8rem;
  }
`

const NavigationColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const Navigation = styled(Link)`
  padding: 1rem 0;
  margin-right: 4rem;
  font-weight: bold;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
`

const Footer = () => {
  return (
    <FooterWrapper>
      <NavigationWrapper>
        <Logo />
        <Links>
          <NavigationColumn>
            <Navigation to="/">Home</Navigation>
            <Navigation to="/about">About</Navigation>
            <Navigation to="/services">Services</Navigation>
          </NavigationColumn>
          <NavigationColumn>
            <Navigation to="/clients">Clients</Navigation>
            <Navigation to="#contact_us">Contact</Navigation>
          </NavigationColumn>
        </Links>
      </NavigationWrapper>
      <Copyright>
        © {new Date().getFullYear()} Wheels On The Bus, LLC. All rights
        reserved.
      </Copyright>
    </FooterWrapper>
  )
}

export default Footer
