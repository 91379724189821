import styled from "styled-components"
import React, { useState } from "react"

const ContactSectionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlueLightest};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
`

const Form = styled.form`
  * {
    box-sizing: border-box;
  }

  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }

  input {
    margin: 0.25rem 0 0 0;
    padding: 10px;
    border-radius: 10px;
    border-width: 0;
    @media (min-width: 768px) {
      min-width: 20rem;
    }
  }

  textarea {
    margin: 0.25rem 0 0 0;
    padding: 10px;
    border-radius: 10px;
    border-width: 0;
    max-width: 100%;
  }
`

const FormInputGrouping = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`

const FormInputsLeft = styled.div`

${FormInputGrouping} {
  @media (min-width: 768px) {
    margin-right: 2rem;
  }
}
`

const FormInputsRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; 

  textarea {
    flex: 1;
  }
`

const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.primaryBlueDark};
  margin-bottom: 3rem;
`

const Submit = styled.button`
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  border-width: 0;
  border-radius: 10px;
  color: white;
  padding: 10px;
`

const Success = styled.h4`
  color: green;
`

const Error = styled.h4`
  color: red;
`

const ContactSection = () => {
  const [formStatus, setFormStatus] = useState(false)

  function submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setFormStatus("SUCCESS")
      } else {
        setFormStatus("FAILED")
      }
    }
    xhr.send(data)
  }

  return (
    <ContactSectionWrapper id="contact_us">
      <Heading>Contact</Heading>
      <Form
        onSubmit={submitForm}
        action="https://formspree.io/mqkgzapn"
        method="POST"
      >
        <FormInputsLeft>
          <FormInputGrouping>
            <label htmlFor="name">First and Last Name: </label>
            <input type="text" id="name" name="name" required />
          </FormInputGrouping>
          <FormInputGrouping>
            <label htmlFor="email">Email: </label>
            <input type="email" id="email" name="email" required />
          </FormInputGrouping>
          <FormInputGrouping>
            <label htmlFor="phone">Phone Number: </label>
            <input type="tel" id="phone" name="phone" />
          </FormInputGrouping>
          <FormInputGrouping>
            <label htmlFor="organization">Your organization: </label>
            <input type="text" id="organization" name="organization" required />
          </FormInputGrouping>
        </FormInputsLeft>
        <FormInputsRight>
          <FormInputGrouping>
            <label htmlFor="message">How can we help you?</label>
            <textarea rows="11" cols="50" id="message" name="message" required />
          </FormInputGrouping>
          <FormInputGrouping>
            {formStatus === "SUCCESS" ? (
              <Success>Thanks! We'll be in touch</Success>
            ) : (
              <Submit>Submit</Submit>
            )}
            {formStatus === "ERROR" && <Error>There was an error submitting the form.</Error>}
          </FormInputGrouping>
        </FormInputsRight>
      </Form>
    </ContactSectionWrapper>
  )
}

export default ContactSection
