import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const theme = {
  font: {
    sans: 'open sans'
  },
  colors: {
    primaryBlue: "#13B1CF",
    primaryBlueDark: "#0E849C",
    primaryBlueLight: "#54C1D7",
    primaryBlueLightest: "#DCECF0",
    secondaryOrange: "#FF874C",
    secondaryOrangeDark: "#CC6D3D",
    secondaryOrangeLight: "#FFBB99",
    gray: "#A2A2A2",
    grayDarker: "#7F7F7F",
    grayDarkest: "#383838",
    grayLighter: "#F7F7F7",
    white: "#FFFFFF",
    teal: "#09E6CF",
    green: "#OAAE9D",
    blue: "#3795DC",
    blueDarker: "#197082",
    gold: "#CFBE27",
    red: "#EB6D67",
  },
  lineHeight: {
    standard: "1.25"
  },
  fontWeight: {
    bold: "bold",
    semibold: "semibold",
    regular: "regular"
  }
}


export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.sans };
    color: '#1a202c';
  }
  body {
    letter-spacing: 0;
    background-color: '#f7fafc';
    font-weight: ${({theme}) => theme.fontWeight.regular};
    font-size: 16px;
    line-height: ${({ theme }) => theme.lineHeight.standard };
  }
  h1 {
    font-weight: ${({ theme }) => theme.fontWeight.bold };
    font-size: 32px;
    @media (min-width: 768px) {
      font-size: 48px;
    }
  }
  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.bold };
    font-size: 24px;
    @media (min-width: 768px) {
      font-size: 40px;
    }
  }
  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.bold };
    font-size: 20px;
    @media (min-width: 768px) {
      font-size: 24px;
    }
  }
  h4 {
    font-weight: ${({ theme }) => theme.fontWeight.bold };
    font-size: 18px;
  }
  button {
    font-weight: ${({ theme }) => theme.fontWeight.bold };
    font-size: 16px;
  }
`